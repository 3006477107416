<template>
    <phi-page id="thread" :loading="posts.loading">
        <div slot="toolbar" class="page-toolbar">
            <mu-icon-button @click="goBack" icon="arrow_back" />
            <h1></h1>
        </div>

        <div slot="header" class="page-header">
            <h1>{{ head ? head.title : '&nbsp;' }}</h1>
        </div>

        <div v-if="!head.expirationDate || (head.expirationDate * 1000) > now || head.author.id == app.user.id">
            <div>
                <phi-post
                    ref="post"
                    v-if="head"
                    v-model="head"
                    class="head"
                    :class="{read: !isUnread(head), unread: isUnread(head), selected: isSelected(head)}"
                    @click="clickPost(head, arguments[0])"
                    :show-trail="allowed(head, 'trail')"
                ></phi-post>

                <div v-for="day in days" class="day" :key="day.key">
                    <label>{{ day.label }}</label>

                    <phi-post
                        v-for="(post, key) in day.posts"
                        :key="post.id"
                        v-model="day.posts[key]"
                        class="short"
                        :class="{read: !isUnread(post), unread: isUnread(post), selected: isSelected(post)}"
                        @click="clickPost(post, arguments[0])"
                        :show-trail="allowed(day.posts[key], 'trail')"
                    ></phi-post>
                </div>
            </div>


            <div slot="footer" class="page-footer">

                <div v-if="selectionMode" class="reply-options">
                    <div @click="shareSelected">
                        <mu-icon value="forward" />
                        {{ $t('action.Share') }} {{ selection.length }}
                    </div>

                    <div @click="cancelSelection" class="option-secondary">
                        <mu-icon value="close" />
                        {{ $t('action.cancel') }}
                    </div>
                </div>
                <div v-else>
                    <phi-drawer :open="!tpl.replyIsOpen" linear>
                        <div class="reply-options">
                            <div v-if="allowed(posts.items[0], 'reply')" class="option-reply" @click="openReply(posts.items[0]);">
                                <mu-icon value="reply" />
                                {{ $t('action.reply') }}
                            </div>
                            <div v-show="!head.replyTarget" v-if="allowed(posts.items[0], 'replyAll')" class="option-reply-all" @click="openReply(posts.items[0], true);">
                                <mu-icon value="reply_all" />
                                {{ $t('action.replyAll') }}
                            </div>
                            <div v-if="allowed(posts.items[0], 'forward') && !waitingForForward" class="option-secondary" @click="forward(head)">
                                <mu-icon value="forward" />
                                {{ $t('action.Forward') }}
                            </div>
                            <div v-if="waitingForForward" class="option-secondary">
                                <mu-circular-progress></mu-circular-progress>
                            </div>
                        </div>
                    </phi-drawer>
                </div>
            </div>

            <phi-post-info
                :post="sidePost.post"
                @close="sidePost.post = null"
                :show-roles="true"
                :show-trail="allowed(sidePost.post, 'trail')"
                :show-audience="allowed(sidePost.post, 'audience')"
                :show-read-date="allowed(sidePost.post, 'readDate')"
            >
                <div class="post-options" v-if="sidePost.post" @click="sidePost.post = null">
                    <div v-if="allowed(sidePost.post, 'reply')" class="post-reply" @click="openReply(sidePost.post)">
                        <mu-icon value="reply"></mu-icon>
                        {{ $t('action.reply') }}
                    </div>
                    <div v-show="!head.replyTarget" v-if="allowed(sidePost.post, 'replyAll')" class="post-reply-all" @click="openReply(sidePost.post, true)">
                        <mu-icon value="reply_all"></mu-icon>
                    </div>
                    <div v-if="allowed(sidePost.post, 'forward')" class="post-forward" @click="forward(sidePost.post)">
                        <mu-icon value="forward"></mu-icon>
                        {{ $t('action.Forward') }}
                    </div>
                    <div v-if="allowed(sidePost.post, 'share')" class="post-share" @click="share(sidePost.post)">
                        <mu-icon value="share"></mu-icon>
                        {{ $t('action.Share') }}
                    </div>
                </div>
            </phi-post-info>
        </div>

        <div v-else>{{ $t('the message is expired') }}</div>
    </phi-page>
</template>


<script>
import app from '@/store/app.js';

import PhiDrawer from '@/components/Phi/Drawer.vue';
import PhiBlock from '@/components/Phi/Block.vue';
import PhiPersonInfo from '@/components/Phi/Person/Info.vue';
import PhiPostInfo from '@/components/Phi/Post/Info.vue';
import PhiPostEditor from '@/components/Phi/Post/Editor.vue';

import PhiPageSidePanel from '@/components/Phi/Page/SidePanel.vue';
import PhiPersonRelevanceSelectorBlock from '@/components/Phi/Person/Relevance/SelectorBlock.vue';

var destroyListener;

/* Recursive equivalent of Object.assign */
var deepObjectAssign = function(target, source) {
	if (typeof target != 'object' || target == null) {   // JavaScript typeof null returns "object" :(
		return source;
	}

	for (var prop in source) {
		if (source.hasOwnProperty(prop)) {
			if (typeof source[prop] === 'object') {
				target[prop] = deepObjectAssign(target[prop], source[prop]);
			} else {
				target[prop] = source[prop];
			}
		}
	}

	return target;
};

export default {
    components: {
        PhiBlock,
        PhiPersonInfo,
        PhiPostInfo,
        PhiPostEditor,
        PhiPageSidePanel,
        PhiPersonRelevanceSelectorBlock

    },

	props: {
		personId: {
			default() {
				return app.user.id;
			}
		}
	},

    data() {
        return {
            app,
            posts: app.api.collection(`/people/${this.personId}/threads/feed/${this.$route.params.threadId}`),
            reply: {
                id: null,
                replyTo: null,
                description: null,
                audience: null,
                blocks: [],
                settings: {
                    allowed: {
                        reply: true,
                        replyAll: true
                    }
                },
                people: []
            },
            replyTimer: null,
            tpl: {
                replyIsOpen: false,
                peopleListIsOpen: false
            },

            selectionMode: false,
            selection: [],

            sidePost: {
                post: null
            },

			isAllowed: {
				compose: false
			},

            waitingForForward: false,
            now: Date.now()
        }
    },

    watch: {
        "$route.params.threadId"() {
            this.posts = this.app.api.collection(`/people/${this.personId}/threads/feed/${this.$route.params.threadId}`);
            this.posts.fetch();
        }
    },

	created() {
		this.app.api
			.allowed(`/people/${this.personId}/posts/sent`)
			.then(boolResponse => this.isAllowed.compose = boolResponse);
	},

    mounted() {
        this.posts.fetch();

        /* Listen for push notifications */
		destroyListener = app.on("notification", stub => {
			if (stub.post.thread2 == this.$route.params.threadId) {
                stub.post.stub = {readDate: stub.post.readDate};
                this.appendPost(stub.post, true);
			}
		});
    },

	___beforeRouteEnter(to, from, next) {
		/*
		!!!
		We need to access "to"'s PROPS as defined in the router,
		but this guard doesn't include to.props (shouldn't it?!),
		anyway, they are available somewhere in to.matched.  Hack them out:
        */
		var props = {};
		for (var i = 0; i < to.matched.length; i++) {
			if (to.matched[i].name == to.name) {
				props = to.matched[i].props.default ? to.matched[i].props.default : {};
				break;
			}
        }
        var personId = props.personId ? props.personId: app.user.id;
		var posts    = app.api.collection(`/people/${personId}/threads/feed/${to.params.threadId}`)
		posts.fetch().then(() => next(vm => vm.posts = posts));
	},

	beforeRouteLeave(to, from, next) {
		destroyListener();
		next();
	},

    computed: {
        head() {
            return this.posts.items[this.posts.items.length - 1];
        },

        days() {
            var slots       = [];
            var currentSlot = null;

            this.posts.items.forEach(post => {
                // Ignore head post
                if (post.id == this.posts.items[this.posts.items.length - 1].id) {
                    return;
                }

                var date = new Date(post.publishDate * 1000);
                var key  = date.getDate() + '.' + date.getMonth() + '.' + date.getFullYear();

                if (!currentSlot || currentSlot.key != key) {
                    currentSlot = {
                        key,
                        label: date.toLocaleString(this.$store.state.i18n.language, {month:'long', day:'numeric'}),
                        posts: []
                    }
                    slots.unshift(currentSlot);
                }

                currentSlot.posts.unshift(post);
            });

            return slots;
        },

        replyTo() {
            return this.reply.replyTo ? this.posts.getItem(this.reply.replyTo) : null;
        },
    },

    methods: {
        goBack() {
            if (!this.$router.previousRoute.name) {
                this.$router.push('/highlights');
            } else {
                this.$router.go(-1);
            }
        },

        appendPost(incomingPost, markAsRead) {
            this.posts.unshift(incomingPost);

            if (markAsRead) {
                this.posts.clear();
                app.api.get(this.posts.url);
            }
        },

        allowed(post, action) {
            if (post == null) {
                return false;
            }

            var defaultSettings = {
                allowed: {
                    "reply":    false,
                    "replyAll": false,
                    "audience": true,
                    "trail":    false,
                    "invites":  true,
                    "readDate": true
                }
            };

            var settings = post.settings == null ? defaultSettings : Object.assign({}, post.settings);
            settings = deepObjectAssign(defaultSettings, settings);

            switch (action) {
                case 'reply':
                    if (post.author.id == this.personId) {
                        return settings.allowed.reply && post.audienceCount == 1;
                    }
                    return settings.allowed.reply;

                case 'replyAll':
                    if (post.author.id == this.personId) {
                        return post.audienceCount > 1;
                    }
                    return post.audienceCount > 1 && settings.allowed.replyAll && settings.allowed.audience;

                case 'audience':
                    return (post.author.id == this.personId) || (post.audienceCount > 0 && settings.allowed.audience);

                case 'trail':
                    return post.replyTo != null && settings.allowed.trail;

                case 'invites':
                    return settings.allowed.invites;

                case 'readDate':
                    return settings.allowed.readDate;

                case 'forward':
                    return (typeof settings.forward!=='undefined')?settings.forward:this.isAllowed.compose;

                case 'share':
                    return this.isAllowed.compose;

                default:
                    return true;
            }

        },

        isUnread(post) {
            return post.stub && !post.stub.readDate;
        },

        isSelected(post) {
            return this.selection.indexOf(post.id) >= 0;
        },

        selectPost(post) {
            if (this.isSelected(post)) {
                return;
            }
            this.selection.push(post.id);

            if (post.quotes && post.quotes.length) {
                post.quotes.forEach(quote => this.selectPost(quote));
            }
        },

        deselectPost(post) {
            this.selection.splice(this.selection.indexOf(post.id), 1);
            if (post.quotes && post.quotes.length) {
                post.quotes.forEach(quote => this.deselectPost(quote));
            }

            if (!this.selection.length) {
                this.selectionMode = false;
            }
        },

        togglePost(post) {
            this.isSelected(post) ? this.deselectPost(post) : this.selectPost(post);
        },

        share(post = undefined) {
            this.selectionMode = true;

            if (post) {
                this.selectPost(post);
            } else {
                this.selection = [];
                this.posts.items.forEach(post => this.selectPost(post));
            }

            if (this.posts.length == 1 && this.selection.length == 1) {
                this.shareSelected();
            }
        },

        forward(post) {
            this.waitingForForward = true;
            return app.api
                .put(`/posts/${post.id}/copy`)
                .then(copy => this.$router.push({name: 'compose', query:{post: copy.id}}))
                .then(() => this.waitingForForward = false);
        },

        cancelSelection() {
            this.selection   = [];
            this.selectionMode = false;
        },

        shareSelected() {
			if (!this.selection.length) {
				return;
			}
            this.$router.push({name: 'compose', query:{fw: this.selection}});
        },

        clickPost(post, target) {
            return this.selectionMode ? this.togglePost(post) : target != 'description' && this.togglePostInfo(post);
        },

        fetchAudience(post) {
            return app.api
                .get(`/posts/${post.id}/stubs`)
                .then(stubs => stubs.map(stub => stub.person));
        },

        togglePostInfo(post) {
            this.sidePost.post = this.sidePost.post == post ? null: post;
        },

        openReply(post, toAll) {
            this.sidePost.post = null;
            this.reply.replyTo = post.id;

            if (toAll || post.author.id == this.personId) {
                var fetchAudience = this
                    .fetchAudience(post)
                    .then(people => this.reply.people = people);
            } else {
                var fetchAudience = Promise.resolve();
            }

            if (post.replyTarget) {
                post.replyTarget = JSON.parse(post.replyTarget);

                var replyTarget = [];
                for (var i = 0; i < post.replyTarget.to.people.length; i++) {
                    replyTarget[i] = post.replyTarget.to.people[i];
                }
            }

            fetchAudience
                .then(() => {
                    /* Append the author if not present */
                    if (post.author.id != this.personId) {
                        var found = false;
                        for (var i = 0; i < this.reply.people.length; i++) {
                            if (this.reply.people[i].id == post.author.id) {
                                found = true;
                                break;
                            }
                        }
                        if (!found) {
                            this.reply.people.unshift(post.author);
                        }
                    }

                    /* Replace audience with replyTarget: and Remove current user from audience */
                    var paramReplyTarget = false;
                    if (post.replyTarget) {
                        this.reply.people = replyTarget.filter(person => person.id != this.personId);
                        paramReplyTarget = true;
                    } else {
                        this.reply.people = this.reply.people.filter(person => person.id != this.personId);
                    }

                    this.reply.expirationDate = post.expirationDate;

                    /* Open and focus the box */
                    this.obtainReply()
                        .then(() => {
                            this.$router.push({name: 'compose', query:{post: this.reply.id}, params:{people: this.reply.people, paramReplyTarget: paramReplyTarget}});
                        });
                });
        },

        obtainReply() {
            return this.reply.id ? Promise.resolve(this.reply) : app.api
                .get(`/people/${this.personId}/posts/drafts`, {
                    type: "mensaje",
                    replyTo: this.reply.replyTo,
                    expirationDate: this.reply.expirationDate,
                    create: 1,
                    nocache: new Date().getTime()
                })
                .then(drafts => {
                    this.reply = Object.assign(this.reply, drafts[0]);

                    // Force reply settings:
                    this.reply.settings = {
                        allowed: {
                            reply: true,
                            replyAll: true
                        }
                    };

                    return this.reply;
                });
        }
    }
}
</script>

<style lang="scss">
.modal-mask {
    z-index: 2;
}

#thread {
	background-color: #1C89B8 !important;

    .page-toolbar,
    .page-header {
        color: #fff;
    }

    .page-toolbar {
        .event {
            padding: 12px;
        }
    }

    .page-header {
        padding: 12px;
    }

    .phi-page-contents {
        background-color: #fff;
        padding: 0;
    }

    .page-footer {
        background-color: #f9f9f9;
    }


    .reply {
        background-color: #f9f9f9;
        border-top: 2px solid #d0d0d0;

        .phi-post-editor {
            background-color: #fff;
        }

        .phi-post-editor-title {
            display: none !important;
        }

        .phi-post-editor-description {
            max-height: 180px;
        }

        .people-list-toggler {
            color: #777;
            padding: 12px 8px;
            align-items: center;

            .phi-media-figure {
                margin: 0;
                padding: 0 3px;
                font-size: 20px;
            }

            .phi-media-body {
                font-size: 12px;
            }

            .phi-media-right {
                color: #900;
                font-size: 16px;
                padding: 0 3px;
            }
        }

        .actions {
            .attach {
                padding: 6px;

                .phi-media-figure {
                    margin-right: .3em;
                    color: #777;
                }

                .phi-media-body {
                    display: none;
                }
            }

            .send {
                padding: 6px;
                color: #5091e0;
                cursor: pointer;

                &.disabled {
                    color: #ddd;
                    pointer-events: none;
                }
            }

        }
    }
}

</style>

<style lang="scss" scoped>
.day {

    border-top: 1px dashed #ccc;
    padding: 16px 0;

    label {
        display: inline-block;
        margin: 0;
        font-weight: bold;
        color: #666;
        font-size: 12px;
        line-height: 12px;
        padding: 0 8px;
        border-radius: 8px;
        background-color: #fff;

        position: relative;
        top: -29px;
        left: 12px;
    }
}


.post {
    position: relative;
    margin-bottom: 18px;
    padding: 0;

    .phi-avatar {
        width: 38px;
        height: 38px;
        min-width: 38px;
        min-height: 38px;
        max-width: 38px;
        max-height: 38px;
    }

    .post-headline {
        display: flex;
        align-items: center;

        .post-author,
        .post-audience {
            margin-right: 0.7em;
        }
    }

    .post-author {
        font-weight: bold;
        font-size: 0.85em;
        color: #000;
    }

    .post-audience,
    .post-date {
        color: #aaa;
        font-size: 12px;
        display: inline;
    }

    .post-description {
        white-space: pre-wrap;
        font-size: 0.95em;
        margin-bottom: 5px;
    }

    .post-preview {
        font-size: 0.9em;
    }

    .post-blocks {
        margin-top: 6px;
    }

	/* check mark */
	.phi-media-figure i {
		font-size: 1.2em;
        line-height: 45px;
		text-align: center;
		color: #1C89B8;

		display: none;
	}

	&.selected {
		background: #ff8;
		.phi-media-figure i {
			display: block;
		}
		.phi-media-figure img {
			display: none;
		}
	}
}


.reply-options {
    display: flex;
    align-items: center;

    background-color: #fff;
    color: #666;
    border-top: 1px solid #eee;

    & > * {
        display: flex;
        align-items: center;

        cursor: pointer;
        padding: 14px 22px;
        font-size: 14px;
        white-space: nowrap;
    }

    .option-secondary {
        flex: 1;
        justify-content: flex-end;
    }

    i {
        margin-right: 0.5em;
    }
}

.post-options {
    margin: 12px 0;

    & > div,
    & > a {
        display: flex;
        align-items: center;

        margin: 0.4em 0;
        padding: 8px 12px;
        border: 0;
        border-radius: 6px;
        background-color: #eee;
        color: #444;

        cursor: pointer;
        white-space: nowrap;

        font-size: 14px;

        .mu-icon {
            margin-right: .5em;
        }
    }
}


.phi-post-event {
    i {
        margin-right: 0.5em;
    }
}

.namelist {
    & > *::after {
        content: ", ";
    }

    & > :last-child::after {
        content: "";
    }
}
</style>